<template>
  <transition name="fade">
    <TMmodal v-show="TMOpen" :isOpen="TMOpen" :type="'header'" @close="onCloseTM"/>
  </transition>
  <section class="hv">
    <div class="infowrap">
      <div class="infotopwrap">
        <div class="infotop" v-if="userData">
          <ul class="mym">
            <li class="mycash">
               <div>
                  <em>Cash</em>
                  <span>₩ {{thousand(userData.cashAmt.toString())}}</span>
               </div>
               <a><img src="@/assets_mobile/img/icon_refresh.svg" />Refresh</a>
            </li>
            <li class="mypoint">
               <div>
                  <em>Point</em>
                  <span>P {{thousand(userData.pointAmt.toString())}}</span>
               </div>
               <a><img src="@/assets_mobile/img/icon_refresh.svg" />Refresh</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import TMmodal from '@/components/common/TotalMoney'

export default {
  name: 'myinfo',
  computed: {
    ...mapState([
      'userData',
      'siteOption'
    ])
  },
  created () {
  },
  methods: {
    async onClickTM () {
      const confirmMessage = '통합머니 전환을 진행하시겠습니까?'
      const confirmResult = await this.onConfirm(confirmMessage)
      if (confirmResult) {
        this.TMOpen = true
      }
    },
    onCloseTM () {
      this.TMOpen = false
    },
    openLang () {
      this.isOpenLang = !this.isOpenLang
    },
    onClickGo (name) {
      this.$router.push({ name: name })
    },
    onClickBack () {
      this.$router.go(-1)
    },
    getMedalClass (level) {
      if (level < 10) {
        return 'medal' + level
      } else {
        return 'medallv'
      }
    },
    goPageByNamez (name, params) {
      const query = { name: name }
      if (params) {
        query.params = params
      }
      this.$router.push(query)
      this.side = !this.side
    }
  },
  components: {
    TMmodal
  },
  data () {
    return {
      isOpenLang: false,
      TMOpen: false,
      side: false
    }
  }
}
</script>
<style scoped src="@/styles_mobile/header.css"></style>
<style scoped src="@/styles_mobile/myinfo.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>
